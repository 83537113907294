<template>
  <component :is="tag" :class="{ [openClass]: isExpanded }">
    <div
      :aria-controls="expandableId"
      :aria-expanded="isExpanded"
      role="button"
      tabindex="0"
      @click="onTriggerClick"
      @keyup.enter="onTriggerClick"
    >
      <slot name="trigger" v-bind="{ open: isExpanded }" />
    </div>

    <div
      :id="expandableId"
      class="grid overflow-hidden transition-all duration-300"
      :class="{
        'grid-rows-[1fr]': isExpanded,
        'grid-rows-[0fr]': !isExpanded,
      }"
    >
      <div class="min-h-0">
        <slot />
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    tag?: keyof HTMLElementTagNameMap;
    defaultExpanded?: boolean;
    disabled?: boolean;
    openClass?: string;
  }>(),
  {
    tag: "div",
    openClass: "",
  },
);

const expandableId = useId();

const [isExpanded, toggle] = useToggle(properties.defaultExpanded);

function onTriggerClick() {
  if (properties.disabled) return;

  toggle();
}

const emit = defineEmits(["close", "expand"]);

watch(isExpanded, (value) => emit(value ? "expand" : "close"));
</script>
